import { getFirestore } from "@firebase/firestore/lite";
import { getAnalytics } from "firebase/analytics";
// Import all of Bootstrap's JS
// import * as bootstrap from "bootstrap";
import { initializeApp } from "firebase/app";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";
import { getAuth } from "firebase/auth";

import * as controller from "./controller";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDgh3uczFl7sfwPzypR4gBab1syENUN7c4",
  authDomain: "rvcroffi-start-page.firebaseapp.com",
  projectId: "rvcroffi-start-page",
  storageBucket: "rvcroffi-start-page.appspot.com",
  messagingSenderId: "984106252667",
  appId: "1:984106252667:web:599dca2f39d946520925de",
  measurementId: "G-YJLFX5E6GT",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(
    "6LdMTEIqAAAAANgeF6ZCtP6en8VRhLR2-nYCna89"
  ),
  isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
});
const analytics = getAnalytics(app);
const auth = getAuth(app);
const firestore = getFirestore(app);

// document.onreadystatechange = () => {
controller.init();
// };
