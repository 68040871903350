import * as controller from "./controller";

const THEME_ICON = {
  AUTO: "bi bi-circle-half",
  LIGHT: "bi bi-sun-fill",
  DARK: "bi bi-moon-fill",
};
let $drpThemeIcon, $drpThemeLinks;

function init() {
  $drpThemeIcon = document.getElementById("drpThemeIcon");
  $drpThemeLinks = document.getElementsByClassName("drpThemeLink");

  for (let i = 0; i < $drpThemeLinks.length; i++) {
    const element = $drpThemeLinks[i];
    element.addEventListener("click", (ev) => {
      onChangeTheme(ev.target.dataset.value);
    });
  }

  loadTheme();
  controller.onAuthChange(() => {
    loadTheme();
  });
}

function loadTheme() {
  let themeName = controller.getTheme();
  setTheme(themeName);
}
function saveTheme(themeName) {
  controller.saveTheme(themeName);
}
function setTheme(themeName) {
  themeName =
    themeName !== "light" && themeName !== "dark" ? "auto" : themeName;
  let theme = themeName;
  if (theme === "auto") {
    theme = window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light";
  }
  document.documentElement.setAttribute("data-bs-theme", theme);
  $drpThemeIcon.classList = THEME_ICON[themeName.toUpperCase()];
}
function onChangeTheme(themeName) {
  saveTheme(themeName);
  setTheme(themeName);
}

export { init };
