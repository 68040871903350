import { Modal, Tooltip } from "bootstrap";
import Sortable from "sortablejs";

import { savedConfig } from "./config_view";
import * as controller from "./controller";
import {
  bindFormIptValidationMsg,
  blockElementsIn,
  changeVisibility,
  ConfirmModal,
  generateId,
  hideLoading,
  showLoading,
  validateForm,
} from "./utils_view";

const GROUP_MAXLENGTH = 50;
const TILE_MAXLENGTH = 30;

let $tileModalTemplate;

function init() {
  $tileModalTemplate = document.getElementById("tileModal");
}

class TileGroup {
  groupId;
  groupName;
  sortable;
  bsTileModal;
  bsDeleteModal;
  $groupContainer;
  $groupItems;
  $addTileBtn;
  $tileModal;
  $tileModalTitle;
  $tileForm;
  $btnSaveTile;
  $tileId;
  $tileLink;
  $tileName;

  constructor(groupId, groupName) {
    this.groupId = groupId;
    this.groupName = groupName;
    this._initGroup();
  }

  renderGroupIn($element) {
    $element.append(this.$groupContainer);
    this._loadTiles();
    this.sortable = initSortable(this.groupId, this.$groupItems);
    this.$groupItems.after(this.$addTileBtn);
    this.checkEditableMode();
  }
  async updateGroupName(newName) {
    if (typeof newName === "string") {
      newName = newName.trim();
      newName = newName.substr(0, GROUP_MAXLENGTH);
      const groupData = { id: this.groupId, name: newName };
      const savedGroup = controller.saveGroupToLocal(groupData);
      const updated = await controller.saveGroupOnline(savedGroup);
      if (updated) {
        this.groupName = newName;
      } else {
        savedGroup.name = this.groupName;
        controller.saveGroupToLocal(savedGroup);
      }
      return updated;
    }
  }
  async removeGroup() {
    const removedGroup = await controller.removeGroupOnline(this.groupId);
    if (removedGroup) {
      const removerTiles = await controller.removeAllTilesFromGroupOnline(
        this.groupId
      );
      if (removerTiles) {
        controller.removeGroupFromLocal(this.groupId);
        controller.removeAllTilesFromGroupLocal(this.groupId);
        this.$groupContainer.remove();
      }
    }
  }
  disableSortable() {
    this.sortable.option("disabled", true);
  }
  enableSortable() {
    this.sortable.option("disabled", false);
  }
  checkEditableMode() {
    const isEditable = savedConfig.isEditableMode;
    const elements =
      this.$groupContainer.getElementsByClassName("toggle-visible");
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      changeVisibility(element, isEditable);
    }
    if (isEditable) {
      this.enableSortable();
    } else {
      this.disableSortable();
    }
  }

  //métodos privados
  _initGroup() {
    if (this.$groupContainer) return;
    this._loadContainerGroupElement();
    this._loadAddTileBtnElement();
    this._loadTileModal();
    this._loadConfirmDeleteGroup();
  }
  _loadContainerGroupElement() {
    const { $container, $groupItems } = this._getContainerGroupElement();
    this.$groupItems = $groupItems;
    this.$groupContainer = $container;
  }
  _loadAddTileBtnElement() {
    this.$addTileBtn = this._getAddBtnTileElement();
    this.$addTileBtn.addEventListener("click", () => {
      this.bsTileModal.show(this);
    });
  }
  _loadTileModal() {
    this.$tileModal = $tileModalTemplate.cloneNode(true);
    this.$tileModal.removeAttribute("id");
    this.$tileModalTitle = this.$tileModal
      .getElementsByClassName("tileModalLabel")
      .item(0);
    this.$tileForm = this.$tileModal.getElementsByTagName("form").item(0);
    this.$tileId = this.$tileModal.getElementsByClassName("tileId").item(0);
    this.$tileLink = this.$tileModal.getElementsByClassName("tileLink").item(0);
    this.$tileName = this.$tileModal.getElementsByClassName("tileName").item(0);
    this.$tileName.setAttribute("maxlength", TILE_MAXLENGTH);
    this.$btnSaveTile = this.$tileModal
      .getElementsByClassName("btnSave")
      .item(0);

    this.bsTileModal = new Modal(this.$tileModal);

    this.$tileModal.addEventListener("hidden.bs.modal", () => {
      this._resetForm();
    });
    this.$tileModal.addEventListener("shown.bs.modal", () => {
      this.$tileLink.focus();
    });

    bindFormIptValidationMsg(this.$tileForm);

    this.$tileLink.addEventListener("keyup", (ev) => {
      if (ev.key === "Enter") this.$tileName.focus();
    });
    this.$tileName.addEventListener("keyup", (ev) => {
      if (ev.key === "Enter")
        this.$btnSaveTile.dispatchEvent(new Event("click"));
    });
    this.$btnSaveTile.addEventListener("click", () => {
      this._onSaveTile();
    });
  }
  _loadConfirmDeleteGroup() {
    this.bsDeleteModal = new ConfirmModal();
  }
  _insertTile($itemCard) {
    this.$groupItems.append($itemCard);
  }
  _replaceTile($oldItem, $newItem) {
    $oldItem.after($newItem);
    $oldItem.remove();
  }
  _renderTileData(tileData) {
    const $itemCard = this._getTileElement(tileData);
    const $oldCard = document.getElementById(tileData.id);
    if ($oldCard) this._replaceTile($oldCard, $itemCard);
    else this._insertTile($itemCard);
  }
  _loadTiles() {
    const items = controller.getTilesFromLocal(this.groupId);
    items.forEach((tileData) => {
      this._renderTileData(tileData);
    });
  }
  _onEditTile(tileData) {
    this.$tileModalTitle.textContent = "Editar link";
    this.$tileId.value = tileData.id;
    this.$tileLink.value = tileData.siteLink;
    this.$tileName.value = tileData.siteName;
    this.bsTileModal.show();
  }
  async _onSaveTile() {
    if (!validateForm(this.$tileForm)) return;
    const tileData = this._getTileDataFromModal();
    blockElementsIn(this.$tileModal, true);
    showLoading();
    const savedTile = controller.saveTileToLocal(tileData, this.groupId);
    const updated = await controller.saveTileOnline(savedTile, this.groupId);
    if (updated) {
      this._renderTileData(savedTile);
      this.sortable.save();
    } else {
      controller.removeTileFromLocal(tileData.id, this.groupId);
    }
    hideLoading();
    blockElementsIn(this.$tileModal, false);
    this.bsTileModal.hide();
  }
  _getTileElement(tileData) {
    const $card = document.createElement("div");
    $card.dataset.id = tileData.id;
    $card.setAttribute("id", tileData.id);
    $card.className = "card card_item item position-relative float-start";
    const $cardImg = document.createElement("img");
    $cardImg.className = "card-img";
    const domain = controller.getDomainFromUrl(tileData.siteLink);
    $cardImg.setAttribute(
      "src",
      `https://www.google.com/s2/favicons?domain=${domain}&sz=48`
      // `chrome-search://ntpicon/?size=48@1.000000x&url=${domain}`
    );
    $cardImg.setAttribute("alt", "icon");
    const $cardBody = document.createElement("div");
    $cardBody.className =
      "card-img-overlay d-flex align-items-end justify-content-center";
    const $cardText = document.createElement("p");
    $cardText.className = "card-text text-truncate small";
    $cardText.innerText = tileData.siteName;
    $cardText.setAttribute("data-bs-title", tileData.siteName);
    $cardText.setAttribute("data-bs-placement", "bottom");

    const $drpBtn = document.createElement("button");
    $drpBtn.setAttribute("type", "button");
    $drpBtn.setAttribute("data-bs-toggle", "dropdown");
    $drpBtn.className =
      "btn_drp btn btn-link btn-sm rounded-circle position-absolute top-0 end-0 toggle-visible";
    const $drpIcon = document.createElement("i");
    $drpIcon.className = "bi bi-three-dots-vertical";
    const $drpMenu = document.createElement("ul");
    $drpMenu.className = "dropdown-menu";
    const $drpItemDel = document.createElement("li");
    const $drpBtnItemDel = document.createElement("button");
    $drpBtnItemDel.setAttribute("type", "button");
    $drpBtnItemDel.className = "dropdown-item";
    const $drpBtnIconDel = document.createElement("i");
    $drpBtnIconDel.className = "bi bi-trash3";
    const $drpItemEdit = document.createElement("li");
    const $drpBtnItemEdit = document.createElement("button");
    $drpBtnItemEdit.setAttribute("type", "button");
    $drpBtnItemEdit.className = "dropdown-item";
    const $drpBtnIconEdit = document.createElement("i");
    $drpBtnIconEdit.className = "bi bi-pencil-square";

    $drpBtnItemEdit.append($drpBtnIconEdit);
    $drpBtnItemEdit.append(" Editar");
    $drpBtnItemDel.append($drpBtnIconDel);
    $drpBtnItemDel.append(" Remover");
    $drpItemEdit.append($drpBtnItemEdit);
    $drpMenu.append($drpItemEdit);
    $drpItemDel.append($drpBtnItemDel);
    $drpMenu.append($drpItemDel);
    $drpBtn.append($drpIcon);
    $cardBody.append($cardText);
    // $cardLink.append($cardBody);
    $card.append($cardImg);
    $card.append($cardBody);
    $card.append($drpBtn);
    $card.append($drpMenu);

    new Tooltip($cardText);

    $drpBtnItemDel.addEventListener("click", () => {
      this._onRemoveTile(tileData.id, $card);
    });
    $drpBtnItemEdit.addEventListener("click", () => {
      this._onEditTile(tileData);
    });
    $cardBody.addEventListener("click", () => {
      openLink(tileData.siteLink);
    });

    return $card;
  }
  _getContainerGroupElement() {
    const $container = document.createElement("div");
    $container.className =
      "py-1 px-md-2 col-12 col-lg-6 col-xxxl-4 container_group"; //mx-auto
    $container.dataset.id = this.groupId;
    const $card = document.createElement("div");
    $card.className = "card border-0";
    const $cardHeader = document.createElement("div");
    $cardHeader.className = "card-header border-0 d-flex align-items-center";
    const $headerText = document.createElement("spam");
    $headerText.textContent = this.groupName ?? "";
    const $divBtnHeaderEnd = document.createElement("div");
    $divBtnHeaderEnd.className = "d-flex ms-auto";
    const $divBtnHeaderStart = document.createElement("div");
    $divBtnHeaderStart.className = "d-flex";
    const $cardBody = document.createElement("div");
    $cardBody.className = "card-body body_group";
    const $groupItems = document.createElement("div");
    $groupItems.className = "group_items";
    $groupItems.dataset.groupId = this.groupId;

    const $divEditGroup = document.createElement("div");
    $divEditGroup.classList = "col-5";
    $divEditGroup.style.display = "none";
    const $divIptGroup = document.createElement("div");
    $divIptGroup.classList = "input-group input-group-sm";
    const $iptEditGroup = document.createElement("input");
    $iptEditGroup.classList = "form-control";
    $iptEditGroup.setAttribute("maxlength", GROUP_MAXLENGTH);
    const $btnSaveEditGroup = document.createElement("button");
    $btnSaveEditGroup.classList = "btn btn-outline-primary";
    $btnSaveEditGroup.textContent = "Ok";
    const $btnCancelEditGroup = document.createElement("button");
    $btnCancelEditGroup.classList = "btn btn-outline-secondary";
    $btnCancelEditGroup.textContent = "Cancelar";

    $divIptGroup.append($iptEditGroup);
    $divIptGroup.append($btnSaveEditGroup);
    $divIptGroup.append($btnCancelEditGroup);
    $divEditGroup.append($divIptGroup);

    const $drpBtnItemDel = document.createElement("a");
    $drpBtnItemDel.setAttribute("href", "#");
    $drpBtnItemDel.setAttribute("data-bs-title", "Remover sessão");
    $drpBtnItemDel.className = "px-2 align-self-center toggle-visible";
    $drpBtnItemDel.style.color = "unset";
    const $drpBtnIconDel = document.createElement("i");
    $drpBtnIconDel.className = "bi bi-trash3";
    const $drpBtnItemEdit = document.createElement("a");
    $drpBtnItemEdit.setAttribute("href", "#");
    $drpBtnItemEdit.className = "px-3 align-self-center toggle-visible";
    $drpBtnItemEdit.style.color = "unset";
    const $drpBtnIconEdit = document.createElement("i");
    $drpBtnIconEdit.className = "bi bi-pencil";
    $drpBtnIconEdit.setAttribute("data-bs-title", "Editar nome");
    $drpBtnItemEdit.append($drpBtnIconEdit);
    $drpBtnItemDel.append($drpBtnIconDel);

    $divBtnHeaderStart.append($drpBtnItemEdit);
    $divBtnHeaderEnd.append($drpBtnItemDel);

    const $handle = document.createElement("div");
    $handle.className = "p-1 group-handle toggle-visible";
    $handle.setAttribute("data-bs-title", "Mover sessão");
    const $handleIcon = document.createElement("i");
    $handleIcon.className = "bi bi-arrows-move";
    $handle.append($handleIcon);
    $divBtnHeaderEnd.append($handle);

    $cardHeader.append($divEditGroup);
    $cardHeader.append($headerText);
    $cardHeader.append($divBtnHeaderStart);
    $cardHeader.append($divBtnHeaderEnd);
    $cardBody.append($groupItems);
    $card.append($cardHeader);
    $card.append($cardBody);
    $container.append($card);

    new Tooltip($drpBtnIconEdit);
    const ttpDel = new Tooltip($drpBtnItemDel);
    new Tooltip($handle);

    $drpBtnItemDel.addEventListener("click", () => {
      ttpDel.hide();
      this._onDeleteGroup();
    });
    $drpBtnItemEdit.addEventListener("click", () => {
      $iptEditGroup.value = this.groupName;
      _showEditHeader();
      $iptEditGroup.focus();
      $iptEditGroup.select();
    });
    $btnSaveEditGroup.addEventListener("click", async () => {
      const newName = $iptEditGroup.value;
      showLoading();
      const updated = await this.updateGroupName(newName);
      if (updated) $headerText.textContent = this.groupName;
      hideLoading();
      _showTextHeader();
    });
    $btnCancelEditGroup.addEventListener("click", () => {
      _showTextHeader();
    });
    $iptEditGroup.addEventListener("keyup", (ev) => {
      if (ev.key === "Enter")
        $btnSaveEditGroup.dispatchEvent(new Event("click"));
    });
    function _showTextHeader() {
      $divEditGroup.style.display = "none";
      $headerText.style.display = "";
      $drpBtnItemEdit.style.display = "";
    }
    function _showEditHeader() {
      $divEditGroup.style.display = "";
      $headerText.style.display = "none";
      $drpBtnItemEdit.style.display = "none";
    }

    return { $container, $groupItems };
  }
  _getAddBtnTileElement() {
    const $card = document.createElement("div");
    $card.className = "card card_item add_item d-inline-flex toggle-visible";
    const $cardBody = document.createElement("div");
    $cardBody.className =
      "card-body d-flex flex-wrap justify-content-center align-items-end text-secondary";
    const $cardIconWrapper = document.createElement("h1");
    $cardIconWrapper.className = "card-text m-0";
    const $cardIcon = document.createElement("i");
    $cardIcon.className = "bi bi-plus-square-dotted";
    const $cardText = document.createElement("p");
    $cardText.className = "m-0";
    $cardText.textContent = "Adicionar";
    $cardIconWrapper.append($cardIcon);
    $cardBody.append($cardIconWrapper);
    $cardBody.append($cardText);
    $card.append($cardBody);

    return $card;
  }
  _getTileDataFromModal() {
    const id = this.$tileId.value || generateId();
    const siteLink = this.$tileLink.value.trim();
    const siteName = this.$tileName.value.trim().substr(0, TILE_MAXLENGTH);
    return { id, siteLink, siteName };
  }
  _onEditTile(tileData) {
    this.$tileModalTitle.textContent = "Editar link";
    this.$tileId.value = tileData.id;
    this.$tileLink.value = tileData.siteLink;
    this.$tileName.value = tileData.siteName;
    this.bsTileModal.show();
  }
  async _onRemoveTile(id, $card) {
    showLoading();
    const isRemoved = await controller.removeTileOnline(id, this.groupId);
    if (isRemoved) {
      controller.removeTileFromLocal(id, this.groupId);
      $card.remove();
      this.sortable.save();
    }
    hideLoading();
  }
  _resetForm() {
    this.$tileModalTitle.textContent = "Adicionar link";
    this.$tileForm.classList.remove("was-validated");
    this.$tileId.value = "";
    this.$tileLink.value = "";
    this.$tileName.value = "";
  }
  async _onDeleteGroup() {
    let ask = controller.getTilesFromLocal(this.groupId).length > 0;
    let confirm = true;
    if (ask) {
      confirm = await this.bsDeleteModal.getConfirm(
        "Os links da sessão serão removidos também."
      );
    }
    if (confirm) {
      showLoading();
      await this.removeGroup();
      hideLoading();
    }
  }
}

function openLink(url) {
  window.open(url, savedConfig.isOpenNewTab ? "_blank" : "_self");
}

function initSortable(groupId, $groupItems) {
  return Sortable.create($groupItems, {
    group: {
      name: groupId, //usar groupId como nome do grupo
      pull: true,
      put: true,
    },
    dataIdAttr: "data-id",
    draggable: ".item",
    // delay: 10,
    animation: 150,
    easing: "cubic-bezier(1, 0, 0, 1)",
    onEnd: async function (evt) {
      if (evt.pullMode) {
        //se mudou de grupo
        const itemData = controller.changeTileByGroupLocal(
          evt.item.id,
          evt.from.dataset.groupId,
          evt.to.dataset.groupId
        );
        const changed = await controller.changeTileByGroupOnline(
          itemData,
          evt.from.dataset.groupId,
          evt.to.dataset.groupId
        );
        if (!changed) {
          //se der pau online, reverte
          controller.changeTileByGroupLocal(
            evt.item.id,
            evt.to.dataset.groupId,
            evt.from.dataset.groupId
          );
        }
      }
    },
    store: {
      //salva e carrega ordenação dos itens
      get: function (sortable) {
        var tiles_order = controller.getTileOrderFromLocal(
          sortable.options.group.name
        ); //<- o name na verdade é o groupID
        // console.log(sortable.options.group.name, order);
        return tiles_order.order;
      },
      set: async function (sortable) {
        var order = sortable.toArray();
        const groupId = sortable.options.group.name;
        const tiles_order = controller.saveTileOrderToLocal(groupId, { order }); //<- o name na verdade é o groupID
        await controller.saveTileOrderOnline(groupId, tiles_order);
        console.log("salvou " + sortable.options.group.name, tiles_order);
      },
    },
  });
}

export { TileGroup, init };
