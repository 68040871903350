import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  setDoc,
  updateDoc,
  writeBatch,
} from "@firebase/firestore/lite";
import {
  applyActionCode,
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

let auth, db;

function init() {
  auth = getAuth();
  auth.useDeviceLanguage();
  db = getFirestore();
}

//Local data
function saveToLocal(key, value) {
  localStorage.setItem(key, value);
}

function getFromLocal(key) {
  return localStorage.getItem(key);
}

function removeFromLocal(key) {
  localStorage.removeItem(key);
}

//firestore
async function setDbData(collectionPath, docData, id) {
  await setDoc(doc(db, collectionPath, id), docData);
}

async function batchInsert(collectionPath, aData) {
  const batch = writeBatch(db);
  aData.forEach((data) => {
    batch.set(doc(db, collectionPath, data.id), data);
  });
  await batch.commit();
}

async function batchUpdate(collectionPath, aData) {
  const batch = writeBatch(db);
  aData.forEach((data) => {
    batch.update(doc(db, collectionPath, data.id), data);
  });
  await batch.commit();
}

async function updateDbData(collectionPath, docData, id) {
  await updateDoc(doc(db, collectionPath, id), docData);
}

async function getDbCollection(collectionPath) {
  const querySnapshot = await getDocs(collection(db, collectionPath));
  return querySnapshot.docs.map((docSnap) => docSnap.data());
}

async function getDbDoc(collectionPath, docId) {
  const querySnapshot = await getDoc(doc(db, collectionPath, docId));
  return querySnapshot.data();
}

async function removeDbDoc(collectionPath, id) {
  await updateDoc(doc(db, collectionPath, id), { deleted: true });
}

async function batchDelete(collectionPath, aIds) {
  const batch = writeBatch(db);
  aIds.forEach((id) => {
    batch.update(doc(db, collectionPath, id), { deleted: true });
  });
  await batch.commit();
}

//authentication
async function createUser(email, password) {
  const userCredential = await createUserWithEmailAndPassword(
    auth,
    email,
    password
  );
  return userCredential.user;
}

async function sendVerificationEmail(user) {
  await sendEmailVerification(user, {
    // url: "http://localhost:1234/?mode=emailVerified",
    url: "https://linkpage.top/?mode=emailVerified",
  });
}

async function handleVerifyEmail(actionCode) {
  await applyActionCode(auth, actionCode);
}

async function loginUser(email, password) {
  const userCredential = await signInWithEmailAndPassword(
    auth,
    email,
    password
  );
  return userCredential.user;
}

async function logout() {
  return signOut(auth);
}

/**
 * https://firebase.google.com/docs/reference/js/auth.user
 * @param {*} callback
 */
function onAuthChange(callback) {
  onAuthStateChanged(auth, (user) => {
    if (typeof callback === "function") callback(user);
  });
}

export {
  init,
  saveToLocal,
  getFromLocal,
  removeFromLocal,
  setDbData,
  batchInsert,
  batchUpdate,
  updateDbData,
  getDbCollection,
  getDbDoc,
  removeDbDoc,
  batchDelete,
  createUser,
  loginUser,
  onAuthChange,
  logout,
  sendVerificationEmail,
  handleVerifyEmail,
};
