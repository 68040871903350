import * as controller from "./controller";
import {
  bindFormIptValidationMsg,
  blockElementsIn,
  hideLoading,
  showLoading,
  validateForm,
} from "./utils_view";

let $chkOpenNewTab,
  $chkEditableMode,
  $formLogin,
  $loginLegend,
  $iptEmailLogin,
  $emailHelp,
  $iptPasswordLogin,
  $chkTosLogin,
  $divChkTos,
  $btnSubmitLogin,
  $btnToogleForm,
  $isAuth,
  $btnLogout,
  $alertVerifyEmail,
  $btnVerifyEmail,
  $alertEmailSent;
let savedConfig, tempUser;

//TODO exportar/importar dados
//TODO remover todos os dados

function init() {
  $chkOpenNewTab = document.getElementById("chkOpenNewTab");
  $chkEditableMode = document.getElementById("chkEditableMode");
  $formLogin = document.getElementById("formLogin");
  $loginLegend = $formLogin.getElementsByTagName("legend").item(0);
  $iptEmailLogin = document.getElementById("iptEmail");
  $emailHelp = document.getElementById("emailHelp");
  $iptPasswordLogin = document.getElementById("iptPassword");
  $divChkTos = document.getElementById("divChkTos");
  $chkTosLogin = document.getElementById("chkTos");
  $btnSubmitLogin = document.getElementById("btnSubmit");
  $btnToogleForm = document.getElementById("btnToogleForm");
  $isAuth = document.getElementById("isAuth");
  $btnLogout = $isAuth.getElementsByClassName("logout").item(0);
  $alertVerifyEmail = document.getElementById("alertVerifEmail");
  $btnVerifyEmail = document.getElementById("btnVerifEmail");
  $alertEmailSent = document.getElementById("alertEmailSent");

  $chkOpenNewTab.addEventListener("change", onSaveConfig);
  $chkEditableMode.addEventListener("change", onSaveConfig);
  $btnSubmitLogin.addEventListener("click", (ev) => {
    const action = ev.target.dataset.action;
    if (action === "login") {
      onLogin();
    } else if (action === "create") {
      onCreateUser();
    }
  });
  $btnToogleForm.addEventListener("click", onToogleForm);
  $btnLogout.addEventListener("click", onLogout);
  $btnVerifyEmail.addEventListener("click", onVerifyEmail);
  bindFormIptValidationMsg($formLogin);

  loadConfig();
  controller.onSyncronize(handleUpdateConfig);
  controller.onAuthChange(onAuthChange);
}

function loadConfig() {
  savedConfig = controller.getConfigFromLocal();
  $chkOpenNewTab.checked = savedConfig.isOpenNewTab;
  $chkEditableMode.checked = savedConfig.isEditableMode;
}

async function onSaveConfig() {
  savedConfig = {
    isOpenNewTab: $chkOpenNewTab.checked,
    isEditableMode: $chkEditableMode.checked,
  };
  savedConfig = controller.saveConfigToLocal(savedConfig);
  await controller.saveConfigOnline(savedConfig);
  controller.onChangeConfig();
}

//Create user
async function onCreateUser() {
  if (!validateForm($formLogin)) return;
  const { email, password } = getLoginFormData();
  blockElementsIn($formLogin, true);
  showLoading();
  await controller.createUser(email, password);
  await controller.startUserOnline(savedConfig);
  controller.copyAnonGroupsToUser();
  controller.copyAnonGroupsOrderToUser();
  blockElementsIn($formLogin, false);
  hideLoading();
}
function getLoginFormData() {
  const email = $iptEmailLogin.value;
  const password = $iptPasswordLogin.value;
  return { email, password };
}

//Login
async function onLogin() {
  if (!validateForm($formLogin)) return;
  const { email, password } = getLoginFormData();
  blockElementsIn($formLogin, true);
  showLoading();
  await controller.loginUser(email, password);
  blockElementsIn($formLogin, false);
  hideLoading();
}

function handleUpdateConfig() {
  loadConfig();
  controller.onChangeConfig();
}

//Autenticado
function showUser(user) {
  const $email = $isAuth.getElementsByClassName("user_email").item(0);
  $email.textContent = user.email;
}

function onAuthChange(user) {
  if (!!user) {
    //autenticado
    $isAuth.style.display = "";
    $formLogin.style.display = "none";
    showUser(user);
  } else {
    $isAuth.style.display = "none";
    $formLogin.style.display = "";
    renderLoginUser();
    loadConfig();
  }
}

function onToogleForm() {
  const action = $btnSubmitLogin.dataset.action;
  if (action === "create") {
    renderLoginUser();
  } else {
    renderCreateUser();
  }
}

function renderCreateUser() {
  $loginLegend.textContent = "Criar conta";
  $btnSubmitLogin.textContent = "Criar";
  $btnSubmitLogin.dataset.action = "create";
  $btnToogleForm.textContent = "Fazer login";
  $emailHelp.style.display = "";
  $divChkTos.style.display = "";
  $chkTosLogin.setAttribute("required", "");
  hideAlertSendVerificationEmail();
  hideAlertEmailSent();
}

function renderLoginUser() {
  $loginLegend.textContent = "Login";
  $btnSubmitLogin.textContent = "Login";
  $btnSubmitLogin.dataset.action = "login";
  $btnToogleForm.textContent = "Criar conta";
  $emailHelp.style.display = "none";
  $divChkTos.style.display = "none";
  $chkTosLogin.removeAttribute("required");
  hideAlertSendVerificationEmail();
  hideAlertEmailSent();
}

function showAlertSendVerificationEmail(user) {
  tempUser = user;
  $alertVerifyEmail.style.display = "";
}
function hideAlertSendVerificationEmail() {
  $alertVerifyEmail.style.display = "none";
}
function showAlertEmailSent() {
  $alertEmailSent.style.display = "";
}
function hideAlertEmailSent() {
  $alertEmailSent.style.display = "none";
}

async function onVerifyEmail() {
  await controller.sendVerificationEmail(tempUser);
  hideAlertSendVerificationEmail();
  showAlertEmailSent();
}

async function onLogout() {
  await controller.logout();
}

export { init, showAlertSendVerificationEmail, savedConfig };
