import Sortable from "sortablejs";

import {
  init as config_init,
  savedConfig,
  showAlertSendVerificationEmail,
} from "./config_view";
import * as controller from "./controller";
import { init as theme_init } from "./theme_view";
import { init as tile_init, TileGroup } from "./tile_group_view";
import {
  changeVisibility,
  generateId,
  hideLoading,
  showLoading,
  init as utils_init,
} from "./utils_view";

/*
TODO i18n (https://phrase.com/blog/posts/step-step-guide-javascript-localization/)
TODO criar recuperação de senha
TODO mostrar versão do package.json no rodapé?
TODO contar cliques nos tiles?
TODO tratar erros (enviar log?)
TODO ver um logo bacana
*/
let $containerGroup,
  $addGroupBtn,
  groups = [],
  groupSortable;

function init() {
  utils_init();
  config_init();
  theme_init();
  tile_init();
  $containerGroup = document.getElementById("containerGroup");
  $addGroupBtn = document.getElementById("addGroupBtn");
  $addGroupBtn.addEventListener("click", onAddGroup);

  groupSortable = initGroupsSortable();
  loadGroups();
  loadGroupsOrder();
  checkEditableMode();

  controller.onAuthChange(async (user) => {
    if (!user) {
      loadGroups();
      loadGroupsOrder();
      checkEditableMode();
    } else {
      showLoading();
      if (!user.emailVerified) {
        await controller.logout();
        showAlertSendVerificationEmail(user);
      } else {
        await controller.syncronize();
      }
      hideLoading();
    }
  });
  controller.onSyncronize(() => {
    loadGroups();
    loadGroupsOrder();
  });
}

function loadGroups() {
  const groups = controller.getGroupsFromLocal();
  clearContainerGroups();
  groups.forEach((grpData) => {
    renderGroup(grpData);
  });
}

function loadGroupsOrder() {
  const groups_order = controller.getGroupOrderFromLocal();
  groupSortable.sort(groups_order.order, false);
}

function renderGroup(groupData) {
  const instanceGroup = new TileGroup(groupData.id, groupData.name);
  instanceGroup.renderGroupIn($containerGroup);
  groups.push(instanceGroup);
}

function clearContainerGroups() {
  while ($containerGroup.firstChild) {
    $containerGroup.removeChild($containerGroup.firstChild);
  }
  groups = [];
}

function toggleEditableMode() {
  checkEditableMode();
  groups.forEach((grp) => {
    grp.checkEditableMode();
  });
}

function checkEditableMode() {
  changeVisibility($addGroupBtn, savedConfig.isEditableMode);
}

function getDefaultGroupData() {
  return {
    id: generateId(),
    name: "Nova sessão",
  };
}

async function onAddGroup() {
  const defaultGrpData = getDefaultGroupData();
  showLoading();
  const savedGroup = controller.saveGroupToLocal(defaultGrpData);
  const saved = await controller.saveGroupOnline(savedGroup);
  if (saved) {
    renderGroup(defaultGrpData);
    groupSortable.save();
  } else {
    controller.removeGroupFromLocal(defaultGrpData.id);
  }
  hideLoading();
}

function initGroupsSortable() {
  return Sortable.create($containerGroup, {
    group: {
      name: "containerGroup",
      put: false,
      pull: false,
    },
    dataIdAttr: "data-id",
    draggable: ".container_group",
    handle: ".group-handle",
    // delay: 10,
    direction: "vertical",
    animation: 150,
    easing: "cubic-bezier(1, 0, 0, 1)",
    // onEnd: function (evt) {},
    store: {
      //salva e carrega ordenação dos itens
      get: function (sortable) {
        var group_order = controller.getGroupOrderFromLocal();
        // console.log(sortable.options.group.name, order);
        return group_order.order;
      },
      set: function (sortable) {
        var order = sortable.toArray();
        const group_order = controller.saveGroupOrderToLocal({ order });
        controller.saveGroupOrderOnline(group_order);
        // console.log("salvou " + sortable.options.group.name, order);
      },
    },
  });
}

export { init, toggleEditableMode };
