import { Modal, Toast } from "bootstrap";

let $loadingBar,
  $confirmModalTemplate,
  $errorToast,
  bsErrorToast,
  $successToast,
  bsSuccessToast;
function init() {
  $loadingBar = document.getElementById("loadingBar");
  $confirmModalTemplate = document.getElementById("confirmModal");
  $errorToast = document.getElementById("errorToast");
  $successToast = document.getElementById("successToast");
  bsErrorToast = new Toast($errorToast, {
    delay: 3000,
  });
  bsSuccessToast = new Toast($successToast, {
    delay: 3000,
  });
}

function blockElementsIn($element, isBlock) {
  const elementsToBlock = ["button", "input", "select", "textarea"];
  elementsToBlock.forEach((tag) => {
    const $elemsToBlock = $element.getElementsByTagName(tag);
    for (let i = 0; i < $elemsToBlock.length; i++) {
      const el = $elemsToBlock[i];
      if (isBlock) el.setAttribute("disabled", true);
      else el.removeAttribute("disabled");
    }
  });
}
function showLoading() {
  $loadingBar.style.display = "";
}
function hideLoading() {
  $loadingBar.style.display = "none";
}

function generateId() {
  return Date.now().toString();
}

function changeVisibility($element, isVisible) {
  if (isVisible) {
    $element.classList.remove("d-none");
  } else {
    $element.classList.add("d-none");
  }
}

function showError(msg) {
  console.error(msg);
  const $body = $errorToast.getElementsByClassName("toast-body").item(0);
  $body.textContent = msg;
  bsErrorToast.show();
}

function showSuccess(msg) {
  const $body = $successToast.getElementsByClassName("toast-body").item(0);
  $body.textContent = msg;
  bsSuccessToast.show();
}

//forms
function loadInputValidationMsg($iptElement) {
  const errMsg = $iptElement.validationMessage;
  let $validationMsg = $iptElement.parentElement
    .getElementsByClassName("invalid-feedback")
    .item(0);
  if (!$validationMsg)
    $validationMsg = $iptElement.parentElement.parentElement
      .getElementsByClassName("invalid-feedback")
      .item(0);
  $validationMsg.textContent = errMsg;
}
function validateForm($formElement) {
  $formElement.classList.add("was-validated");
  const inputs = $formElement.getElementsByTagName("input");
  for (let i = 0; i < inputs.length; i++) {
    const $ipt = inputs[i];
    loadInputValidationMsg($ipt);
  }
  return $formElement.checkValidity();
}
function bindFormIptValidationMsg($formElement) {
  const inputs = $formElement.getElementsByTagName("input");
  for (let i = 0; i < inputs.length; i++) {
    const $ipt = inputs[i];
    $ipt.addEventListener("keyup", () => {
      loadInputValidationMsg($ipt);
    });
  }
}

class ConfirmModal {
  $modal;
  $btnConfirm;
  $btnsClose;
  $bodyModal;
  bsModal;

  constructor() {
    this._init();
  }

  _init() {
    this.$modal = $confirmModalTemplate.cloneNode(true);
    this.$modal.removeAttribute("id");
    this.$btnConfirm = this.$modal.getElementsByClassName("btnConfirm").item(0);
    this.$btnsClose = this.$modal.getElementsByClassName("btnClose");
    this.$bodyModal = this.$modal.getElementsByClassName("modal-body").item(0);
    this.bsModal = new Modal(this.$modal);
  }

  getConfirm(bodyMsg) {
    const $body = document.createElement("p");
    $body.textContent = bodyMsg;
    this.$bodyModal.replaceChildren($body);
    return new Promise((resolve) => {
      const onConfirm = () => {
        this.bsModal.hide();
        resolve(true);
      };
      const onCancel = () => {
        this.bsModal.hide();
        resolve(false);
      };
      this.$btnConfirm.addEventListener("click", onConfirm);
      for (let i = 0; i < this.$btnsClose.length; i++) {
        const $btn = this.$btnsClose[i];
        $btn.addEventListener("click", onCancel);
      }
      this.$modal.addEventListener(
        "hidden.bs.modal",
        () => {
          this.$btnConfirm.removeEventListener("click", onConfirm);
          for (let i = 0; i < this.$btnsClose.length; i++) {
            const $btn = this.$btnsClose[i];
            $btn.removeEventListener("click", onCancel);
          }
        },
        { once: true }
      );
      this.bsModal.show();
    });
  }
}

export {
  init,
  blockElementsIn,
  showLoading,
  hideLoading,
  loadInputValidationMsg,
  validateForm,
  bindFormIptValidationMsg,
  ConfirmModal,
  changeVisibility,
  showError,
  showSuccess,
  generateId,
};
